import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { sendOtp, updateRequest, verifyOTP } from "../../../../services/api";
import OtpInput from "react-otp-input";
import va from "@vercel/analytics";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha"; // Import reCAPTCHA

const modalStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  zIndex: 1000,
};

const containerStyle = {
  background: "white",
  padding: "2rem",
  borderRadius: "0.5rem",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  color: "#666",
  cursor: "pointer",
};

const AuthModal = ({ closeModal, book, dispatch, token, profileModalOpen, addCardModalOpen, selectedPaymentMode }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value); // Set to true if a CAPTCHA value is received
  };

  const handleVerifyOTP = () => {
    // OTP verification logic here
  };

  const handleSendOTP = async () => {
    if (!phoneNumber) {
      toast.error("Please enter a phone number");
      return;
    }

    if (!captchaVerified) {
      toast.error("Please complete the CAPTCHA");
      return;
    }

    const response = await sendOtp({ phoneNumber: phoneNumber });
    if (response.status === 200) {
      localStorage.setItem("phone_number", phoneNumber);
      setOtpSent(true);
      setOtp("");
    } else {
      toast.error("Failed to send OTP. Invalid number!");
    }
  };

  const handleVerify = async (otp) => {
    va.track("handle_otp");

    if (otp.length !== 4) {
      toast.error("Please enter a valid OTP.");
      return;
    }

    try {
      const response = await verifyOTP({ otp: otp, phoneNumber: localStorage.getItem("phone_number") }, token);

      if (response.status === 200) {
        const { user, tokens } = response.data;
        dispatch({ type: "SET_TOKEN", token: tokens.access.token });

        localStorage.setItem("token", tokens.access.token);
        localStorage.setItem("user", JSON.stringify(user));
        closeModal();

        if (!user.isProfileCompleted) {
          profileModalOpen();
        } else {
          await book();
        }
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleInputChange = (newOtp) => {
    setOtp(newOtp);

    if (newOtp.length === 4) {
      handleVerify(newOtp);
    }
  };

  return (
    <div style={modalStyle}>
      <div style={containerStyle}>
        <button style={closeButtonStyle} onClick={closeModal}>
          <AiOutlineClose size={20} />
        </button>
        {!otpSent ? (
          <>
            <h2 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1rem" }}>
              Enter Phone Number
            </h2>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              international
              defaultCountry={"AE"}
              onChange={setPhoneNumber}
              style={{
                border: "2px solid #E5E7EB",
                borderRadius: "4px",
                width: "100%",
                padding: "1rem 1rem",
                color: "#4B5563",
                lineHeight: "1.25",
                outline: "none",
                backgroundColor: "white",
                transition: "background-color 0.2s, border-color 0.2s",
                fontSize: "1.25rem",
              }}
            />
            <ReCAPTCHA
              sitekey="6LcFPXcqAAAAAK4NqqQh82wG1WxYVAlN-FpnBTc6" // Replace with your reCAPTCHA site key
              onChange={handleCaptchaChange}
              style={{ marginTop: "1rem" }}
            />
            <button
              style={{
                marginTop: "1rem",
                width: "100%",
                background: 'linear-gradient(to right, #49BC92, #49BC92)',
                color: "white",
                fontWeight: "bold",
                padding: "0.5rem 1rem",
                borderRadius: "4px",
                cursor: "pointer",
                border: "none",
              }}
              onClick={handleSendOTP}
              disabled={!phoneNumber || !captchaVerified}
            >
              Send OTP
            </button>
          </>
        ) : (
          <>
            <p style={{ fontWeight: "bold", marginBottom: "0.5rem", textAlign: "center" }}>
              Please enter the verification code sent to {phoneNumber}
            </p>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
              <OtpInput
                value={otp}
                onChange={handleInputChange}
                numInputs={4}
                inputType="number"
                renderSeparator={<span>&nbsp;</span>}
                containerStyle={{ width: "350px", justifyContent: "center" }}
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  fontSize: "24px",
                  border: "2px solid #082061",
                  borderRadius: "4px",
                }}
                renderInput={(props, index) => (
                  <input pattern="\d*" {...props} autoFocus={index === 0} />
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthModal;
